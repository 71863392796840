import { useNavigate, useParams } from 'react-router-dom';
import { EditVisualizationForm, PartialDashboardData } from '../form';
import { DashboardDataRepresentation } from '../view/dashboard/widgets/interfaces';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { Spinner } from '@cloudscape-design/components';
import { EmptyState } from '../view/dashboard/components/empty-state';

export const VisualizationsEdit = () => {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();

  const {
    data,
    isLoading,
    error: hasError,
  } = useInstanceSWR<DashboardDataRepresentation>(`/intuition/dashboards/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const handleSubmitForm = async (dashboard: PartialDashboardData) => {
    const { title, feature, ...dashboardData } = dashboard;
    const response = await handleApiWithFlash(`/intuition/dashboards/${feature}`, 'PUT', {
      successMessage: `Successfully updated dashboard ${title ?? feature}`,
      errorMessage: `Error updating dashboard ${title ?? feature}`,
      mutateKey: `/intuition/dashboards/${feature}`,
      data: { title, ...dashboardData },
    });

    if (response?.success) {
      navigate(`/visualizations/view/${feature}`);
    } else {
      navigate(`/visualizations`);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (hasError || !data?.data) {
    return (
      <EmptyState
        title='Error loading dashboard data'
        description='Please refresh and check network connectivity'
        verticalCenter={true}
      />
    );
  }

  const formProps = {
    handleSubmitForm,
    dashboard: { ...data.data, title: data.data.title ?? data.data.feature },
  };

  return <EditVisualizationForm {...{ ...formProps }} />;
};
