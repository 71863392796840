import React, { useEffect, useMemo, useState } from 'react';
import { DashboardDataRepresentation } from '../widgets/interfaces';
import useChangeDetector from '../../../../../utils/useChangeDetector';
import { Layout } from 'react-grid-layout';

const dashboardContext = {
  dashboard: {} as DashboardDataRepresentation,
  layout: [] as Array<Layout>,
  dashboardShadow: {} as DashboardDataRepresentation,
  changesDetected: false as boolean,
  isLoading: false as boolean,
  hasError: false as boolean,
  setIsLoading: (() => null) as React.Dispatch<React.SetStateAction<boolean>>,
  setHasError: (() => null) as React.Dispatch<React.SetStateAction<boolean>>,
  setLayout: (() => null) as React.Dispatch<React.SetStateAction<Array<Layout>>>,
  setDashboard: (() => null) as React.Dispatch<React.SetStateAction<DashboardDataRepresentation>>,
  setDashboardShadow: (() => null) as React.Dispatch<DashboardDataRepresentation>,
};

const DashboardContext = React.createContext<typeof dashboardContext>(dashboardContext);

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }
  return context;
};

interface DashboardProviderProps {
  children: React.ReactNode;
}

export interface WidgetUpdate<DefaultPreferences> {
  preferences?: DefaultPreferences;
  description?: string;
  title?: string;
  filters?: {
    channels?: Array<string>;
    queues?: Array<string>;
  };
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }) => {
  const {
    item: dashboard,
    setItem: setDashboard,
    setItemShadow: setDashboardShadow,
    changesDetected,
    itemShadow: dashboardShadow,
  } = useChangeDetector<DashboardDataRepresentation>({} as DashboardDataRepresentation);

  const [layout, setLayout] = useState<Array<Layout>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (dashboard.data) {
      const newLayout = dashboard.data.items.map((widget) => {

        return {
          x: widget.coordinates.x,
          y: widget.coordinates.y,
          w: widget.size.width,
          h: widget.size.height,
          i: widget.id,
        };

      });

      setLayout(newLayout);
    }
  }, [dashboard]);

  useEffect(() => {
    !!layout && setIsLoading(false);
  }, [layout]);

  const value = useMemo(
    () => ({
      hasError,
      setHasError,
      layout,
      setLayout,
      isLoading,
      setIsLoading,
      dashboard,
      setDashboard,
      setDashboardShadow,
      changesDetected,
      dashboardShadow,
    }),
    [hasError, layout, isLoading, dashboard, changesDetected, dashboardShadow],
  );

  return <DashboardContext.Provider {...{ value }}>{children}</DashboardContext.Provider>;
};
