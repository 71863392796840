import { Header, Spinner } from '@cloudscape-design/components';
import { WidgetConfig, WidgetContentProps, WidgetHeaderProps } from '../interfaces';
import useIntuitionSWR from '../../../../../../utils/hooks/useIntuitionSWR';
import AgentStatusWidget from './agentStatusWidget';
import { AgentStatusPreferenceType, AgentStatusTypes, AgentStatusesObjects } from './types';
import { ApiResponse } from '../../../../../../types/api';
import { AgentStatusProvider } from './agentStatusProvider';
import { useEffect, useState } from 'react';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import moment from 'moment';
import { queueArnsToQueueIds } from '../../utils/widget.helpers';
import styles from './agentStatus.module.scss';
import { EmptyState } from '../../components/empty-state';

export const getAgentStatus: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 2, minRowSpan: 1 },
  data: {
    icon: 'table',
    title: 'Agent Status',
    description: 'Realtime Agent Status',
    provider: AgentStatusProvider,
    header: AgentStatusHeader,
    content: AgentStatus,
    staticMinHeight: 560,
    multipleAllowed: true,
    className: styles.agentStatusBody,
  },
  preferences: {
    order: [AgentStatusTypes.Routable, AgentStatusTypes.Custom, AgentStatusTypes.Error],
  },
};

function AgentStatusHeader({ widgetId }: WidgetHeaderProps) {
  const { getWidget } = useDashboardManager();

  const widget = getWidget<AgentStatusPreferenceType>(widgetId);
  const { title, description } = widget.data;
  return (
    <Header
      variant='h2'
      description={title}
    >
      {description}
    </Header>
  );
}

export default function AgentStatus({ widgetId }: WidgetContentProps) {
  const { getWidget } = useDashboardManager();
  const widget = getWidget<AgentStatusPreferenceType>(widgetId);
  let queues: string[] | null = widget.filters?.queues ?? null;

  if (queues?.length === 1 && queues[0] === '*') {
    queues = [];
  }

  const params = new URLSearchParams();
  params.append('templateType', 'getAgentStatus');
  params.append('queues', queueArnsToQueueIds(queues));
  const url = `/queries?${params.toString()}`;

  const endpoint = url;

  const [agentStatuses, setAgentStatuses] = useState<AgentStatusesObjects>([]);

  const { data, isLoading, error } = useIntuitionSWR<ApiResponse<AgentStatusesObjects>>(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  useEffect(() => {
    const { order = [] } = widget.preferences;
    const agents =
      data?.data.sort((a, b) => {
        const aIndex = order.indexOf(a.currentStatus.type);
        const bIndex = order.indexOf(b.currentStatus.type);

        return aIndex - bIndex || moment(a.currentStatus.startTimeStamp).valueOf() - moment(b.currentStatus.startTimeStamp).valueOf();
      }) || [];
    setAgentStatuses(agents);
  }, [data, widget.preferences]);

  if (!data?.data?.length || !!error || !widget.filters?.queues?.length) {
    return (
      <EmptyState
        title='No agent data available'
        description='Currently no agent data is available.'
        verticalCenter={true}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return <AgentStatusWidget agents={agentStatuses} />;
}
