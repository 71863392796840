// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
/* eslint-disable @typescript-eslint/no-var-requires */
export const mixedContent = {
    light: require('../assets/mixed-content-light.svg').default,
    dark: require('../assets/mixed-content-dark.svg').default,
  };
  
  export const barChart = {
    light: require('../assets/bar-chart-light.svg').default,
    dark: require('../assets/bar-chart-dark.svg').default,
  };
  
  export const lineChart = {
    light: require('../assets/line-chart-light.svg').default,
    dark: require('../assets/line-chart-dark.svg').default,
  };
  
  export const list = {
    light: require('../assets/list-light.svg').default,
    dark: require('../assets/list-dark.svg').default,
  };
  
  export const pieChart = {
    light: require('../assets/pie-chart-light.svg').default,
    dark: require('../assets/pie-chart-dark.svg').default,
  };
  
  export const table = {
    light: require('../assets/table-light.svg').default,
    dark: require('../assets/table-dark.svg').default,
  };
  
  export const number = {
    light: require('../assets/number-light.svg').default,
  };
  
  export const userIcon = {
    light: require('../assets/user-icon.svg').default,
  };
  
