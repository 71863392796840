import { Header } from '@cloudscape-design/components';
import { WidgetConfig, WidgetHeaderProps } from '../interfaces';
import InstanceQueueMetrics from './instanceQueueMetrics';
import { InstanceQueueMetricsProvider } from './instanceQueueMetricsProvider';
import { useDashboardManager } from '../../hooks/useDashboardManager';

export const getInstanceQueueMetrics: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 2, minRowSpan: 1 },
  data: {
    icon: 'table',
    title: 'Queue metrics',
    description: 'Realtime Queue Data',
    header: QueueMetricsHeader,
    content: InstanceQueueMetrics,
    provider: InstanceQueueMetricsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

function QueueMetricsHeader({ widgetId }: Readonly<WidgetHeaderProps>) {
  const { getWidget } = useDashboardManager();

  const widget = getWidget(widgetId);
  const { title, description } = widget.data;
  return (
    <Header
      variant='h2'
      description={title}
    >
      {description}
    </Header>
  );
}
