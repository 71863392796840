import styles from './singleMetric.module.scss';
import { SingleMetricPreferences } from './singleMetricTypes';
import { FormattedMetricDataPoint, InstanceMetricApiResponse } from '../interfaces/queueMetricsInterfaces';
import { WidgetDataRepresentation } from '../interfaces';
import { memo } from 'react';
import { getColorClassName } from '../../utils/widget.helpers';

interface MetricDisplayProps {
  widget: WidgetDataRepresentation<SingleMetricPreferences>;
  data: InstanceMetricApiResponse;
}

const MetricDisplay = memo(({ widget, data }: MetricDisplayProps) => {
  const { metric, ascending } = widget.preferences;
  const metricDataPoint = data.metricSummary.find((element) => element.name === metric) as FormattedMetricDataPoint;

  const minValue = widget.preferences.minValue ?? 10;
  const maxValue = widget.preferences.maxValue ?? 30;

  const colorClassName = getColorClassName(metricDataPoint?.value, minValue, maxValue, ascending);

  return (
    <section className={`${styles.singleMetric} ${styles[colorClassName]}`}>
      <span className={styles.metric}>{metricDataPoint?.value || 0}</span>
    </section>
  );
});

export default MetricDisplay;
