import { parse, validate } from '@aws-sdk/util-arn-parser';
import { QueueMetrics } from '../widgets/interfaces/queueMetricsInterfaces';

export const secondsToHuman = (seconds: number): string => {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
};

export const getRealtimeQueueMetricValue = (queueMetric: QueueMetrics, metricName: string): number => {
  return queueMetric.metrics.find((m) => m.Metric.Name === metricName)?.Value ?? 0;
};

export const queueArnsToQueueIds = (queueArns: Array<string> | null) => {

  if (!queueArns) {
    return JSON.stringify(null);
  }
  return JSON.stringify(queueArns.filter((queueArn) => validate(queueArn)).map((queueArn) => parse(queueArn).resource.split('/').pop()));
};

export const queueArnsToQueueIdParams = (queueArns: string[] = []): string => {
  if (queueArns.filter((i) => i !== '*').length === 0) {
    return '';
  }
  return encodeURIComponent(queueArnsToQueueIds(queueArns));
};

export const getColorClassName = (metricDataPointValue: number, minValue = 10, maxValue = 30, ascending = true) => {
  let lowClass = 'success';
  let highClass = 'error';

  if (!ascending) {
    lowClass = 'error';
    highClass = 'success';
  }

  if (metricDataPointValue < minValue) {
    return lowClass;
  }

  if (metricDataPointValue >= maxValue) {
    return highClass;
  }

  return 'warning';
};
