import { Header } from '@cloudscape-design/components';
import { WidgetConfig, WidgetHeaderProps } from '../interfaces';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { InstanceQueueMetric } from './instanceQueueMetrics';
import { InstanceQueueMetricProvider } from './instanceQueueMetricsProvider';
import { QueueMetricPreferences } from './preferences';

export const getInstanceQueueMetric: WidgetConfig<QueueMetricPreferences> = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 2, minRowSpan: 1 },
  data: {
    icon: 'list',
    title: 'Queue metric',
    description: 'Realtime queue data for 1 queue as a wallboard',
    header: ChartHeader,
    content: InstanceQueueMetric,
    provider: InstanceQueueMetricProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
  preferences: {
    enabledMetrics: [],
    thresholds: {},
  },
};

function ChartHeader({ widgetId }: WidgetHeaderProps) {
  const { getWidget } = useDashboardManager();
  const widget = getWidget(widgetId);
  const { title } = widget.data;
  return (
    <Header
      variant='h2'
      description='Realtime queue data'
    >
      {title}
    </Header>
  );
}
