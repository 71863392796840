import { Spinner, Alert, Button } from '@cloudscape-design/components';
import { useContext } from 'react';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { EmptyState } from '../../components/empty-state';
import { SingleMetricWidgetContext } from './provider';
import { WidgetContentProps } from '../interfaces';
import useIntuitionSWR from '../../../../../../utils/hooks/useIntuitionSWR';
import { SingleMetricPreferences } from './singleMetricTypes';
import { InstanceMetricApiResponse } from '../interfaces/queueMetricsInterfaces';
import { queueArnsToQueueIdParams } from '../../utils/widget.helpers';
import MetricDisplay from './metricDisplay';

export const SingleMetricContent = ({ widgetId }: WidgetContentProps) => {
  const singleMetricWidgetContext = useContext(SingleMetricWidgetContext);
  const { getWidget } = useDashboardManager();
  const widget = getWidget<SingleMetricPreferences>(widgetId);

  const queueIdParams = queueArnsToQueueIdParams(widget?.filters?.queues);
  const endpoint = `/instance-queue-metrics?queueIds=${queueIdParams}`;
  const { data, isLoading, error } = useIntuitionSWR<InstanceMetricApiResponse>(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (!widget.preferences?.metric || !widget.filters?.queues?.length) {
    return (
      <EmptyState
        title='No Data to display'
        description=''
        action={<Button onClick={singleMetricWidgetContext.openPreferences}>Preferences</Button>}
      />
    );
  }

  if (error || !data) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load widget'
      >
        {error.status}
      </Alert>
    );
  }

  return <MetricDisplay { ...{ widget, data}} />
};
