import React from 'react';
import {Container, Spinner, SpaceBetween, ContentLayout, Header} from '@cloudscape-design/components';
import {useParams} from "react-router-dom";

export function DashboardLoading() {
    const {featureId} = useParams();

    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header>
                        {featureId}
                    </Header>
                </SpaceBetween>
            }
        >
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Loading your dashboard"
                    >
                        <Spinner/>
                    </Header>
                }
            >
            </Container>
        </ContentLayout>
    );
}
