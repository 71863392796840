import { Spinner, ColumnLayout, Box, Button, ColumnLayoutProps } from '@cloudscape-design/components';
import { useContext } from 'react';
import useIntuitionSWR from '../../../../../../utils/hooks/useIntuitionSWR';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { EmptyState } from '../../components/empty-state';
import { InstanceMetricApiResponse } from '../interfaces/queueMetricsInterfaces';
import { queueArnsToQueueIdParams, getRealtimeQueueMetricValue, getColorClassName } from '../../utils/widget.helpers';
import { InstanceQueueMetricWidgetContext } from './instanceQueueMetricsProvider';
import styles from './instanceQueueMetrics.module.scss';
import { EnabledMetrics, QueueMetricPreferences } from './preferences';
import { SingleThresholdPreferences } from '../../components/threshold-preferences';

function isQueueMetricPreferences(preferences: any): preferences is QueueMetricPreferences {
  // use this function to check the type of widget during compilation time
  return (
    preferences &&
    typeof preferences === 'object' &&
    'enabledMetrics' in preferences &&
    Array.isArray(preferences.enabledMetrics) &&
    'thresholds' in preferences &&
    typeof preferences.thresholds === 'object'
  );
}

export const InstanceQueueMetric = ({ widgetId }: { widgetId: string }) => {
  const { openPreferences } = useContext(InstanceQueueMetricWidgetContext);
  const { getWidget } = useDashboardManager();
  const widget = getWidget<QueueMetricPreferences | EnabledMetrics>(widgetId); // EnabledMetrics is backwards compatible for existing widgets

  const queueIdParams = queueArnsToQueueIdParams(widget?.filters?.queues);

  const getParams = queueIdParams ? `?queueIds=${queueIdParams}` : '';

  const endpoint = `/instance-queue-metrics${getParams}`;
  const { data, isLoading, error } = useIntuitionSWR<InstanceMetricApiResponse>(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  let enabledMetrics: EnabledMetrics = [];
  let thresholds: { [metric: string]: SingleThresholdPreferences | undefined } = {};

  if (isQueueMetricPreferences(widget.preferences)) {
    ({ enabledMetrics, thresholds } = widget.preferences);
  } else if (Array.isArray(widget.preferences)) {
    enabledMetrics = widget.preferences;
  }

  const colorClassMap: { [key: string]: string } = {};
  enabledMetrics.forEach((metric) => {
    const threshold = thresholds[metric];
    if (threshold) {
      const metricDataPointValue = data?.metricSummary.find((element) => element.name === metric)?.value ?? 0;

      colorClassMap[metric] = getColorClassName(metricDataPointValue, threshold.minValue, threshold.maxValue, threshold?.ascending);
    }
  });

  if (Array.isArray(widget.preferences) || !queueIdParams) {
    return (
      <EmptyState
        title='No data to display'
        description='Open widget preferences to choose a queue and configure some data to be displayed.'
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  if (error || !data) {
    return (
      <EmptyState
        title='Error retrieving data'
        description='This could be due to no data being received for this queue. Please select a different queue'
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  const [queueMetrics] = data.queues;

  const columnLayoutProps: ColumnLayoutProps = {
    columns: 8,
    variant: 'default',
    minColumnWidth: 170,
    borders: 'none',
    disableGutters: true,
  };
  return (
    <ColumnLayout
      {...{ ...columnLayoutProps }}
      data-style='grid-container'
    >
      {widget.preferences.enabledMetrics.map((preference) => {
        const metricWidgetColor = `${styles.boxItem} ${styles[colorClassMap[preference]]}`;
        const label = preference
          .split('_')
          .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
          .join(' ');

        return (
          <div
            key={preference}
            className={metricWidgetColor}
          >
            <Box variant='awsui-key-label'>{label}</Box>
            <Box
              fontSize='display-l'
              fontWeight='bold'
            >
              {getRealtimeQueueMetricValue(queueMetrics, preference)}
            </Box>
          </div>
        );
      })}
    </ColumnLayout>
  );
};
