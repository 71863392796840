import { DateRangePickerProps } from '@cloudscape-design/components';
import fetcherInstance from '../../../../../utils/fetcherInstance';
import { useAppSession } from '../../../../../utils/hooks/sessionContext';
import { useApiWithFlash } from '../../../../../utils/hooks/useApiWithFlashInstance';
import { DashboardDataRepresentation, WidgetDataRepresentation, WidgetConfig } from '../widgets/interfaces';
import { useDashboardContext, WidgetUpdate } from '../context/dashboardContext';
import { Layout } from 'react-grid-layout';
import { allWidgets } from '../widgets';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const useDashboardManager = () => {
  const appSession = useAppSession();
  const { handleApiWithFlash } = useApiWithFlash();

  const { dashboard, dashboardShadow, setDashboard, setHasError, setDashboardShadow, setIsLoading } = useDashboardContext();

  async function saveDashboard() {
    const { feature, ...dashboardData } = cloneDeep(dashboard);
    setIsLoading(true);

    await handleApiWithFlash(`/intuition/dashboards/${feature}`, 'PUT', {
      successMessage: `Successfully updated dashboard  ${dashboardData.title ?? feature}`,
      errorMessage: `Error updating dashboard ${dashboardData.title ?? feature}`,
      mutateKey: `/intuition/dashboards/${feature}`,
      data: dashboardData,
    });
    setDashboardShadow(dashboard);
    setIsLoading(false);
  }

  const loadDashboard = async (dashboardId: string) => {
    try {
      setIsLoading(true);

      const dashboardResponse = await fetcherInstance(`/intuition/dashboards/${dashboardId}`, 'GET', null, appSession?.instanceSelected?.instanceArn);

      const newDashboard = dashboardResponse.data as DashboardDataRepresentation;

      setDashboard(newDashboard);
      setDashboardShadow(newDashboard);

      setIsLoading(false);
    } catch (e) {
      setHasError(true);
    }
  };

  const resetDashboard = async () => {
    setDashboard(dashboardShadow);
  };

  const handleDateChange = (value: DateRangePickerProps.Value | null) => {
    if (value === null || !dashboard?.data) {
      return;
    }
    setDashboard(
      (prevItem: DashboardDataRepresentation): DashboardDataRepresentation => ({
        ...prevItem,
        data: {
          ...prevItem.data,
          period: value,
        },
      }),
    );
  };

  const getWidget = <DefaultPreferences=any,>(widgetId: string) => {
    return dashboard.data.items.find((w: { id: string }) => w.id === widgetId) as WidgetDataRepresentation<DefaultPreferences>;
  };

  const setWidget = <DefaultPreferences=WidgetConfig['preferences'],>(
    widgetId: string,
    { preferences, description, title, filters }: WidgetUpdate<DefaultPreferences>,
  ) => {
    setDashboard((previousDashboard) => {
      const updatedItems = previousDashboard.data.items.map((item) => {
        const isCurrentWidget = item.id === widgetId;

        if (!isCurrentWidget) {
          return item;
        }

        const currentWidget = { ...item };

        currentWidget.preferences = preferences ?? currentWidget.preferences;
        currentWidget.data.description = description ?? item.data.description;
        currentWidget.data.title = title ?? item.data.title;
        currentWidget.filters = filters ?? item.filters;

        return currentWidget;
      });

      return {
        ...previousDashboard,
        data: {
          ...previousDashboard.data,
          items: updatedItems,
        },
      };
    });
  };

  const removeWidget = (widgetId: string) => {
    setIsLoading(true);

    setDashboard((previousDashboard) => {
      const updatedWidgets = previousDashboard.data.items.filter((widget) => widget.id !== widgetId);

      return {
        ...previousDashboard,
        data: {
          ...previousDashboard.data,
          items: updatedWidgets,
        },
      };
    });
  };

  const mergeLayoutAndWidgets = (widgets: Array<WidgetDataRepresentation>, layout: Array<Layout>) => {
    return widgets
      .filter((widget) => !!widget.id)
      .map((widget) => {
        const placement = layout.find(({ i }) => i === widget.id) as Layout;
        return {
          ...widget,
          coordinates: { x: placement.x, y: placement.y },
        };
      });
  };

  const addWidget = (layout: Array<Layout>, item: Layout) => {
    // const offSetKey = dashboard.data.items.length && Object.keys(dashboard.data.items[0].columnOffset).pop() || 2;

    const widgetTemplate = appSession?.activeWidget?.templateType;
    const widgetSize = appSession?.activeWidget?.size;
    appSession.setActiveWidget(undefined);

    if (!widgetTemplate || !widgetSize) {
      return;
    }

    setIsLoading(true);
    const widgetConfig = allWidgets[widgetTemplate];
    const widget = cloneDeep({
      coordinates: { x: item.x, y: item.y },
      size: { width: item.w, height: item.h },
      data: widgetConfig.data,
      templateType: widgetConfig.templateType ?? widgetTemplate,
      id: widgetConfig.data.multipleAllowed ? uuidv4() : (widgetConfig.templateType ?? widgetTemplate),
      filters: {
        channels: widgetConfig.filters?.channels || ['*'],
        queues: widgetConfig.filters?.queues || ['*'],
      },
      preferences: widgetConfig.preferences || [],
    });

    const update = (prevItem: DashboardDataRepresentation) => {
      return {
        ...prevItem,
        data: {
          ...prevItem.data,
          items: [...mergeLayoutAndWidgets(prevItem.data.items, layout), widget],
        },
      };
    };

    setDashboard(update);
  };

  const handleLayoutChange = (layout: ReadonlyArray<Layout>) => {
    const updatedLayout = [...layout]; // Clone the layout for modification

    const updatedWidgets = updatedLayout.map((currentLayout) => {
      const originalWidget = dashboard.data.items.find(({ id }) => id === currentLayout.i) as WidgetDataRepresentation;

      return {
        ...originalWidget,
        coordinates: { x: currentLayout.x, y: currentLayout.y },
        size: { width: currentLayout.w, height: currentLayout.h },
      };
    });

    setDashboard((prevItem) => ({
      ...prevItem,
      data: {
        ...prevItem.data,
        items: updatedWidgets,
      },
    }));
  };

  return { saveDashboard, resetDashboard, loadDashboard, handleDateChange, handleLayoutChange, setWidget, getWidget, removeWidget, addWidget };
};
