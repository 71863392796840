import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { DashboardProvider, useDashboardContext } from './dashboard/context/dashboardContext';
import { useDashboardManager } from './dashboard/hooks/useDashboardManager';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { DashboardLoading } from './dashboard/components/dashboard-loading';
import { DashboardError } from './dashboard/components/dashboard-error';

export default function VisualizationsView() {
  return (
    <DashboardProvider>
      <VisualizationContent />
    </DashboardProvider>
  );
}

function VisualizationContent() {
  const appSession = useAppSession();
  const [initialised, setInitialised] = useState<boolean>();
  const { featureId } = useParams();
  const { isLoading, hasError } = useDashboardContext();
  const { loadDashboard } = useDashboardManager();

  useEffect(() => {
    const canLoad = !initialised && featureId && appSession?.instanceSelected?.instanceArn;

    if (canLoad) {
      loadDashboard(featureId);
      setInitialised(true);
    }
  }, [featureId, appSession]);

  if (isLoading) {
    return <DashboardLoading />;
  } else if (hasError) {
    return <DashboardError />;
  } else {
    return <Dashboard />;
  }
}
