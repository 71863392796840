import * as React from 'react';
import Multiselect from '@cloudscape-design/components/multiselect';
import { useEffect, useMemo } from 'react';
import { useDashboardManager } from '../../hooks/useDashboardManager';

type OptionDefinition = {
  label: string;
  value: string;
  description: string;
};

interface QueuePickerProps {
  widgetId: string;
}

const ChannelPicker: React.FC<QueuePickerProps> = ({ widgetId }) => {
  const { getWidget, setWidget } = useDashboardManager();
  const [selectedOptions, setSelectedOptions] = React.useState<OptionDefinition[]>([]);

  const staticOptions: OptionDefinition[] = useMemo(
    () => [
      { label: 'All', value: '*', description: 'All Channels' },
      { label: 'VOICE', value: 'VOICE', description: 'Voice Channel' },
      { label: 'CHAT', value: 'CHAT', description: 'Chat Channel' },
      { label: 'TASK', value: 'TASK', description: 'Task Channel' },
    ],
    [],
  );

  const getInitialSelectedOptions = (): OptionDefinition[] => {
    const currentWidget = getWidget(widgetId);

    if (!currentWidget?.filters?.channels) {
      return [];
    }

    const allChannels = currentWidget.filters?.channels.includes('*');

    if (allChannels) {
      return [{ label: 'All', value: '*', description: 'All Channels' }];
    }

    return currentWidget.filters.channels.map((channel: string) => {
      const option = staticOptions.find((opt) => opt.value === channel);
      return option || { label: channel, value: channel, description: '' };
    });
  };

  useEffect(() => {
    setSelectedOptions(getInitialSelectedOptions);
  }, [staticOptions, widgetId]);

  const updateWidgetChannels = (selectedChannels: string[]) => {
    const widget = getWidget(widgetId);
    const filters = {
      ...widget.filters,
      channels: selectedChannels,
    };
    setWidget(widgetId, { filters });
  };

  const handleSelectionChange = (selectedOpts: OptionDefinition[]) => {
    if (selectedOpts.some((opt) => opt.value === '*')) {
      setSelectedOptions([{ label: 'All', value: '*', description: 'All Channels' }]);
      updateWidgetChannels(['*']);
    } else {
      setSelectedOptions(selectedOpts);
      updateWidgetChannels(selectedOpts.map((opt) => opt.value));
    }
  };

  return (
    <div>
      <Multiselect
        filteringType='auto'
        selectedOptions={selectedOptions}
        onChange={({ detail }) => handleSelectionChange(detail.selectedOptions as OptionDefinition[])}
        options={staticOptions}
        placeholder='Select channels'
        tokenLimit={2}
      />
    </div>
  );
};

export default ChannelPicker;
