import React from 'react';
import { CollectionPreferences, CollectionPreferencesProps, StatusIndicator } from '@cloudscape-design/components';
import { QueueMetrics } from '../interfaces/queueMetricsInterfaces';
import { TablePreferenceProps } from '../interfaces';
import { getRealtimeQueueMetricValue, secondsToHuman } from '../../utils/widget.helpers';

const rawColumns = [
  {
    id: 'queueName',
    header: 'Queue',
    cell: (item: QueueMetrics) => (<><StatusIndicator type={item.status}/>{item.queueName || ''}</>),
    width: 100,
    isRowHeader: true,
    sortingField: 'queueName',
  },
  {
    id: 'AGENTS_AFTER_CONTACT_WORK',
    header: 'ACW',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_AFTER_CONTACT_WORK');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'AGENTS_AVAILABLE',
    header: 'Available',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_AVAILABLE');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'AGENTS_ERROR',
    header: 'Error',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_ERROR');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'AGENTS_NON_PRODUCTIVE',
    header: 'Non-Productive',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_NON_PRODUCTIVE');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'AGENTS_ON_CALL',
    header: 'On Call',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_ON_CALL');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'AGENTS_ON_CONTACT',
    header: 'On Contact',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_ON_CONTACT');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'AGENTS_ONLINE',
    header: 'Online',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_ONLINE');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'AGENTS_STAFFED',
    header: 'Staffed',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'AGENTS_STAFFED');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'CONTACTS_IN_QUEUE',
    header: 'In Queue',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'CONTACTS_IN_QUEUE');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'CONTACTS_SCHEDULED',
    header: 'Scheduled',
    cell: (item: QueueMetrics) => {

      return getRealtimeQueueMetricValue(item, 'CONTACTS_SCHEDULED');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'OLDEST_CONTACT_AGE',
    header: 'Oldest Age',
    cell: (item: QueueMetrics) => {
      return secondsToHuman(getRealtimeQueueMetricValue(item, 'OLDEST_CONTACT_AGE'));
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'SLOTS_ACTIVE',
    header: 'Active Slots',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'SLOTS_ACTIVE');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
  {
    id: 'SLOTS_AVAILABLE',
    header: 'Available Slots',
    cell: (item: QueueMetrics) => {
      return getRealtimeQueueMetricValue(item, 'SLOTS_AVAILABLE');
    },
    width: 100,
    isRowHeader: true,
    sortingField: 'no_of_contactIds',
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map((column) => ({ ...column }));

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map((column) => column);

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'queueName', label: 'Queue', alwaysVisible: true },
  { id: 'AGENTS_AFTER_CONTACT_WORK', label: 'ACW', alwaysVisible: false },
  { id: 'AGENTS_AVAILABLE', label: 'Available', alwaysVisible: false },
  { id: 'AGENTS_ERROR', label: 'Error', alwaysVisible: false },
  {
    id: 'AGENTS_NON_PRODUCTIVE',
    label: 'Non-Productive',
    alwaysVisible: false,
  },
  { id: 'AGENTS_ON_CALL', label: 'On Call', alwaysVisible: false },
  { id: 'AGENTS_ON_CONTACT', label: 'On Contact', alwaysVisible: false },
  { id: 'AGENTS_ONLINE', label: 'Online', alwaysVisible: false },
  { id: 'AGENTS_STAFFED', label: 'Staffed', alwaysVisible: false },
  { id: 'CONTACTS_IN_QUEUE', label: 'In Queue', alwaysVisible: false },
  { id: 'CONTACTS_SCHEDULED', label: 'Scheduled', alwaysVisible: false },
  { id: 'OLDEST_CONTACT_AGE', label: 'Oldest Age', alwaysVisible: false },
  { id: 'SLOTS_ACTIVE', label: 'Active Slots', alwaysVisible: false },
  { id: 'SLOTS_AVAILABLE', label: 'Available Slots', alwaysVisible: false },
];
export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 rows' },
  { value: 30, label: '30 rows' },
  { value: 50, label: '50 rows' },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 10,
  contentDisplay: [
    { id: 'queueName', visible: true },
    { id: 'CONTACTS_IN_QUEUE', visible: true },
    { id: 'OLDEST_CONTACT_AGE', visible: true },
    { id: 'CONTACTS_SCHEDULED', visible: true },
    { id: 'AGENTS_AFTER_CONTACT_WORK', visible: true },
    { id: 'AGENTS_AVAILABLE', visible: true },
    { id: 'AGENTS_ON_CALL', visible: true },
    { id: 'AGENTS_ON_CONTACT', visible: true },
    { id: 'AGENTS_ONLINE', visible: true },
    { id: 'SLOTS_ACTIVE', visible: true },
    { id: 'SLOTS_AVAILABLE', visible: true },
    { id: 'AGENTS_STAFFED', visible: true },
    { id: 'AGENTS_ERROR', visible: true },
    { id: 'AGENTS_NON_PRODUCTIVE', visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: 'compact',
  stickyColumns: { first: 1, last: 0 },
};



export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}: TablePreferenceProps) => (
  <CollectionPreferences
    title={'Preferences'}
    cancelLabel={'Cancel'}
    confirmLabel={'Confirm'}
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{ title: 'Page size', options: pageSizeOptions }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Select to see all the text and wrap the lines',
    }}
    stripedRowsPreference={{
      label: 'Striped rows',
      description: 'Select to add alternating shaded rows',
    }}
    contentDensityPreference={{
      label: 'Compact mode',
      description: 'Select to display content in a denser, more compact mode',
    }}
    contentDisplayPreference={{
      title: 'Select visible content / order',
      options: contentDisplayOptions,
    }}
    stickyColumnsPreference={{
      firstColumns: {
        title: 'Stick first column(s)',
        description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
        options: [
          { label: 'None', value: 0 },
          { label: 'First column', value: 1 },
          { label: 'First two columns', value: 2 },
        ],
      },
      lastColumns: {
        title: 'Stick last column',
        description: 'Keep the last column visible while horizontally scrolling the table content.',
        options: [
          { label: 'None', value: 0 },
          { label: 'Last column', value: 1 },
        ],
      },
    }}
  />
);
