import {
  getAbandonStats,
  getAgentSummaryStats,
  getAgentStatus,
  getAverageHandleTimeStats,
  getAverageSpeedOfAnswerStats,
  getClosures,
  getContactTraceRecord,
  getInstanceQueueMetric,
  getInstanceQueueMetrics,
  getIvrContainment,
  getQuicksight,
  getQueueSummaryStats,
  getSentimentByQueue,
  getSentimentByAgent,
  getSingleMetric
  // getContactLensIssues,
  // getCustomerJourney,
  // getConversations,
  // getMessages,
  // getModelLatency,
  // getUserFeedback,
  // getUserFeedbackComments,
} from './widgets/';
import { DashboardWidgetItem, WidgetConfig } from './widgets/interfaces';

export type { DashboardWidgetItem };

export const allWidgets: Record<string, WidgetConfig> = {
  getAbandonStats,
  getAgentSummaryStats,
  getAgentStatus,
  getAverageHandleTimeStats,
  getAverageSpeedOfAnswerStats,
  getClosures,
  getContactTraceRecord,
  getInstanceQueueMetric,
  getInstanceQueueMetrics,
  getIvrContainment,
  getQuicksight,
  getQueueSummaryStats,
  getSentimentByQueue,
  getSentimentByAgent,
  getSingleMetric
  // getContactLensIssues,
  // getCustomerJourney,
  // getConversations,
  // getMessages,
  // getModelLatency,
  // getUserFeedback,
  // getUserFeedbackComments,
};

export function getPaletteWidgets(utilisedWidgets: ReadonlyArray<string>) {
  return Object.entries(allWidgets)
    .filter(([id]) => !utilisedWidgets.includes(id))
    .map(([id, widget]) => ({ id, ...widget }));
}
