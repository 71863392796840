import React, { useId } from "react";
import Box from "@cloudscape-design/components/box";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Modal from "@cloudscape-design/components/modal";
import styles from "./preferences.module.scss";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import ChannelPicker from "../../components/channel-picker";
import QueuePicker from "../../components/queue-picker";

export const allContent = [
  "status",
  "running",
  "monitoring",
  "issues",
  "breakdown",
] as const;

export type Content = (typeof allContent)[number];

interface WidgetPreferencesProps {
  preferences?: ReadonlyArray<Content>;
  onDismiss: () => void;
  onConfirm?: (visibleContent: ReadonlyArray<Content>) => void;
  widgetId: string;
}

export function WidgetPreferences({
  onDismiss,
  widgetId,
}: Readonly<WidgetPreferencesProps>) {
  return (
    <Modal
      visible={true}
      header="Preferences"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" formAction="none" onClick={onDismiss}>
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout columns={1} borders="horizontal">
        <PreferencesControl
          label="Channels"
          isGroup={true}
          toggle={() => <ChannelPicker widgetId={widgetId} />}
        />
        <PreferencesControl
          label="Queues"
          isGroup={true}
          toggle={() => <QueuePicker widgetId={widgetId} />}
        ></PreferencesControl>
      </ColumnLayout>
    </Modal>
  );
}

interface PreferencesControlProps {
  label: string;
  isGroup?: boolean;
  toggle?: (id: string) => React.ReactNode;
}

function PreferencesControl({
  label,
  toggle,
  isGroup,
}: Readonly<PreferencesControlProps>) {
  const id = useId();
  return (
    <div className={styles.displayPreference}>
      <label
        htmlFor={id}
        className={`${styles.displayPreferenceLabel} ${
          isGroup ? styles.displayPreferenceGroup : ""
        }`}
      >
        {label}
      </label>
      {toggle?.(id)}
    </div>
  );
}
