import { Header, Spinner } from '@cloudscape-design/components';
import { WidgetConfig, WidgetContentProps } from '../interfaces';
import GetWidgetData from '../../../../../../utils/getWidgetData';
import { useDashboardManager } from '../../hooks/useDashboardManager';

import Alert from '@cloudscape-design/components/alert';
import { TrendInfo } from '../../components/trend-info';
import { useDashboardContext } from '../../context/dashboardContext';
import { AverageHandleTimeStatsProvider } from './provider';

export const getAverageHandleTimeStats: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'number',
    title: 'Avg Handle Time',
    description: 'AHT, plus 7 day average',
    header: ChartHeader,
    content: AverageHandleTimeStatsContent,
    provider: AverageHandleTimeStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

function ChartHeader() {
  return (
    <Header
      variant='h2'
      description='AHT with 7 day average'
    >
      Avg Handle Time
    </Header>
  );
}

function AverageHandleTimeStatsContent({ widgetId }: WidgetContentProps) {
  const { dashboard } = useDashboardContext();
  const { getWidget } = useDashboardManager();
  const widget = getWidget(widgetId);
  const period = dashboard.data.period;
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  if (error) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load widget'
      >
        {error.status}
      </Alert>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '40px',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '6em', fontWeight: '800', textAlign: 'center' }}>{data?.data?.value !== null ? data?.data?.value : 'N/A'}</div>
        {data?.data?.trend !== undefined && (
          <TrendInfo
            trend={data?.data?.trend}
            value={data?.data?.value}
          />
        )}
      </div>
      {isLoading && <Spinner />}
    </div>
  );
}
