import React from 'react';

import styles from './trend.module.scss';
interface TrendInfoProps {
  trend: number | null;
  value: number | null;
  higherIsBetter?: boolean;
}

const getTrendColor = (trend: number | null, value: number | null, higherIsBetter: boolean): string => {
  if (trend === value || trend === null || value === null) {
    return 'grey';
  }

  const isTrendHigher = trend > value;
  return isTrendHigher === higherIsBetter ? 'green' : 'red';
};

const getTrendDirection = (trend: number | null, value: number | null): string => {
  if (trend === value || trend === null || value === null) {
    return '-';
  }

  return trend > value ? '↓' : '↑';
};
export const TrendInfo: React.FC<TrendInfoProps> = ({ trend, value, higherIsBetter = true }) => {
  const colour = getTrendColor(trend, value, higherIsBetter);
  const trendDirection = getTrendDirection(trend, value);
  return (
    <div className={styles.trendContainer}>
      <div className={styles.trendBody}>
        <span style={{ color: colour }}>{trend ?? 'N/A'}</span>
        <span
          className={styles.trendDirection}
          style={{ color: colour }}
        >
          {trendDirection}
        </span>
      </div>
    </div>
  );
};
