import { useState } from 'react';
import Box from '@cloudscape-design/components/box';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import Modal from '@cloudscape-design/components/modal';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import FormField from '@cloudscape-design/components/form-field';
import Select, { SelectProps } from '@cloudscape-design/components/select';
import { Input } from '@cloudscape-design/components';
import { SingleMetricPreferences } from './singleMetricTypes';
import ThresholdPreferences, { SingleThresholdPreferences } from '../../components/threshold-preferences';

import { useDashboardManager } from '../../hooks/useDashboardManager';
import QueuePicker from '../../components/queue-picker';
import { useInitialise } from '../../../../../../utils/hooks/useInitialise';

const ascendingMetrics = ['AGENTS_ONLINE', 'CONTACTS_IN_QUEUE', 'OLDEST_CONTACT_AGE'];

const metrics: SelectProps.Option[] = [
  { value: 'AGENTS_AFTER_CONTACT_WORK', label: 'Agents After Contact Work', description: 'Agents After Contact Work' },
  { value: 'AGENTS_AVAILABLE', label: 'Agents Available', description: 'Agents Available' },
  { value: 'AGENTS_ERROR', label: 'Agents Error', description: 'Agents Error' },
  { value: 'AGENTS_NON_PRODUCTIVE', label: 'Agents Non Productive', description: 'Agents Non Productive' },
  { value: 'AGENTS_ON_CALL', label: 'Agents On Call', description: 'Agents On Call' },
  { value: 'AGENTS_ON_CONTACT', label: 'Agents On Contact', description: 'Agents On Contact' },
  { value: 'AGENTS_ONLINE', label: 'Agents Online', description: 'Agents Online' },
  { value: 'AGENTS_STAFFED', label: 'Agents Staffed', description: 'Agents Staffed' },
  { value: 'CONTACTS_IN_QUEUE', label: 'Contacts In Queue', description: 'Contacts In Queue' },
  { value: 'CONTACTS_SCHEDULED', label: 'Contacts Scheduled', description: 'Contacts Scheduled' },
  { value: 'OLDEST_CONTACT_AGE', label: 'Oldest Contact Age', description: 'Oldest Contact Age' },
  { value: 'SLOTS_ACTIVE', label: 'Slots Active', description: 'Slots Active' },
  { value: 'SLOTS_AVAILABLE', label: 'Slots Available', description: 'Slots Available' },
];

export interface WidgetPreferencesProps {
  onDismiss: () => void;
  widgetId: string;
}

export function WidgetPreferences({ onDismiss, widgetId }: WidgetPreferencesProps) {
  const { getWidget, setWidget } = useDashboardManager();

  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(null);
  const widget = getWidget<SingleMetricPreferences>(widgetId);
  const { metric: metricName } = widget.preferences || {};

  const hasQueue = !!widget.filters?.queues?.length;

  useInitialise(() => {
    const selectedMetric = metrics.find((metric) => metric.value === metricName);
    setSelectedOption(selectedMetric || null);
  });

  const updateWidgetItem = (preferences: Partial<SingleMetricPreferences>, description?: string) => {
    setWidget(widgetId, { preferences: { ...widget.preferences, ...preferences }, description });
  };

  const handleSelectionChange = (selectedOpt: SelectProps.Option) => {
    setSelectedOption(selectedOpt);
    updateWidgetItem({ metric: selectedOpt.value!, ascending: !!ascendingMetrics.includes(selectedOpt.value!) }, selectedOpt.label);
  };

  const handleTitleChange = (title: string) => {
    setWidget(widgetId, { title });
  };

  const handlePreferenceChange = (newPreferences: Partial<SingleThresholdPreferences>) => {
    updateWidgetItem(newPreferences);
  };

  return (
    <Modal
      visible={true}
      header='Preferences'
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >
        <FormField
          description='Select a queue from the dropdown below to view its realtime data'
          label='Queue'
        >
          <QueuePicker widgetId={widgetId} />
        </FormField>
        {hasQueue && (
          <>
            <FormField description='Set the name of the widget'>
              <Input
                value={widget.data.title}
                onChange={({ detail }) => handleTitleChange(detail.value)}
              />
            </FormField>
            <FormField
              description='Select a metric from the dropdown below to view its data'
              label='Metric'
            >
              <Select
                filteringType='auto'
                onChange={({ detail }) => handleSelectionChange(detail.selectedOption as SelectProps.Option)}
                options={metrics}
                placeholder='Select metric'
                selectedOption={selectedOption}
              />
            </FormField>
          </>
        )}
        {selectedOption && (
          <ThresholdPreferences
            preferences={widget.preferences}
            onChange={handlePreferenceChange}
          />
        )}
      </ColumnLayout>
    </Modal>
  );
}
