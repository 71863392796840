import React, { createContext, useMemo, useState } from "react";
import { Content, allContent, WidgetPreferences } from "../preferences";

interface AverageSpeedOfAnswerStatsWidgetContextType {
    visibleContent: ReadonlyArray<Content>;
    openPreferences: () => void;
  }
  
  const AverageSpeedOfAnswerStatsWidgetContext = createContext<AverageSpeedOfAnswerStatsWidgetContextType>({
    visibleContent: [],
    openPreferences: () => null,
  });
  
export function AverageSpeedOfAnswerStatsProvider({ children, widgetId }: { children: React.ReactElement; widgetId: string }) {
    const [preferencesVisible, setPreferencesVisible] = useState(false);
    const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);
    const openPreferences = () => setPreferencesVisible(true);
    const value = useMemo(() => ({ visibleContent, openPreferences }), [visibleContent]);
    return (
      <AverageSpeedOfAnswerStatsWidgetContext.Provider { ...{ value }}>
        {React.cloneElement(React.Children.only(children), {
          removeConfirmationText: 'Operational metrics',
          actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
        })}
        {preferencesVisible && (
          <WidgetPreferences
            widgetId={widgetId}
            preferences={visibleContent}
            onConfirm={(visibleContent) => {
              setVisibleContent(visibleContent);
              setPreferencesVisible(false);
            }}
            onDismiss={() => setPreferencesVisible(false)}
          />
        )}
      </AverageSpeedOfAnswerStatsWidgetContext.Provider>
    );
  }
