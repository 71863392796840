import { useDashboardManager } from '../../../hooks/useDashboardManager';

import styles from '../styles.module.scss';
import { ButtonDropdown, Icon } from '@cloudscape-design/components';
import { ProtectedUtilityUpdate } from '../../../../../../../components/protectedUtility';
import { ResourceTypes } from '../../../../../../../types/rolePermissions';
import { allWidgets } from '../../../widgets';

export interface GridWidgetProps {
  widgetId: string;
  actions?: ReadonlyArray<{
    text: string;
    onClick: () => void;
  }>;
}

export const GridWidget = ({ widgetId, actions = [] }: GridWidgetProps) => {
  const { getWidget, removeWidget } = useDashboardManager();

  const widget = getWidget(widgetId);

  const widgetConfig = allWidgets[widget.templateType];

  const Content = widgetConfig.data.content;
  const Header = widgetConfig.data.header;

  const customClassName = widgetConfig.data.className ?? '';

  return (
    <section className={`${widget.id} ${customClassName}`}>
      <header className={styles.gridWidgetHeader}>
        <div className={'dragger ' + styles.dragger}>
          <Icon name='drag-indicator' />
        </div>
        <Header widgetId={widget.id} />
        <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
          <ButtonDropdown
            items={[...actions.map((action) => ({ id: action.text, text: action.text })), { id: 'remove', text: 'Remove' }]}
            ariaLabel='Widget settings'
            variant='icon'
            onItemClick={(event) => {
              if (event.detail.id === 'remove') {
                removeWidget(widget.id);
              } else {
                const actionMatch = actions.find((action) => action.text === event.detail.id)!;
                actionMatch.onClick();
              }
            }}
          />
        </ProtectedUtilityUpdate>
      </header>
      <section className={styles.gridContent}>
        <Content
          widgetId={widget.id}
          className={widget.id}
        />
      </section>
    </section>
  );
};
