import GetWidgetData from '../../../../../../utils/getWidgetData';
import { useDashboardContext } from '../../context/dashboardContext';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { WidgetTableProps } from '../interfaces';
import WidgetTable from './table';

export default function QueueSummaryStatsContent({ widgetId }: { widgetId: string }) {
  const { dashboard } = useDashboardContext();
  const { getWidget } = useDashboardManager();
  const widget = getWidget(widgetId);
  const period = dashboard.data.period;
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  const widgetTableProps: WidgetTableProps = {
    widgetId,
    isLoading,
    data: data?.data,
    error,
  };

  return <WidgetTable {...widgetTableProps} />;
}
