import * as React from 'react';
import Select from '@cloudscape-design/components/select'; // Make sure to import Select component properly
import { useDashboardManager } from '../../hooks/useDashboardManager';
import useIntuitionSWR from '../../../../../../utils/hooks/useIntuitionSWR';
import { useEffect } from 'react';

type Queue = {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: string;
};

interface QueuePickerSingleResponse {
  data: Queue[];
}

type OptionDefinition = {
  label: string;
  value: string;
  description: string;
};

interface SingleQueuePickerProps {
  widgetId: string;
}

const QueuePickerSingle: React.FC<SingleQueuePickerProps> = ({ widgetId }) => {
  const { getWidget, setWidget } = useDashboardManager();
  const [selectedOption, setSelectedOption] = React.useState<OptionDefinition | null>(null);

  const { data, error } = useIntuitionSWR<QueuePickerSingleResponse>(`/instance-config/queues`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const isLoading = !data && !error;

  const transformQueueData = (queues: Queue[]): OptionDefinition[] => {
    return queues.map((queue) => ({
      label: queue.Name,
      value: queue.Arn,
      description: queue.QueueType,
    }));
  };

  const options = React.useMemo(() => {
    return isLoading || !data ? [] : transformQueueData(data.data);
  }, [isLoading, data]);

  useEffect(() => {
    const currentWidget = getWidget(widgetId);
    if (currentWidget?.filters?.queues) {
      const queue = options.find((opt) => opt.value === currentWidget.filters?.queues?.[0]);
      if (queue) {
        setSelectedOption(queue);
      }
    }
  }, [options, widgetId]);
  const updateWidgetItem = (selectedArns: string[], newTitle?: string) => {
    const widget = getWidget(widgetId);

    setWidget(widgetId, {
      title: newTitle,
      filters: {
        ...widget.filters,
        queues: selectedArns,
      },
    });
  };

  const handleSelectionChange = (selectedOpt: OptionDefinition) => {
    setSelectedOption(selectedOpt);
    updateWidgetItem([selectedOpt.value], selectedOpt.label); // Pass selectedOpt.label as second argument
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div>
      <Select
        filteringType='auto'
        onChange={({ detail }) => handleSelectionChange(detail.selectedOption as OptionDefinition)}
        options={options}
        placeholder='Select queue'
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default QueuePickerSingle;
