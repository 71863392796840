import React, { createContext, useState } from 'react';
import { Header, LineChart, Box, Button } from '@cloudscape-design/components';
import { commonChartProps, dateTimeFormatter, lineChartInstructions } from '../chart-commons';
import { WidgetConfig } from '../interfaces';
import { DataPayload } from '../../../../../messages/types';
import GetWidgetData from '../../../../../../utils/getWidgetData';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { allContent, Content, WidgetPreferences } from '../preferences';
import { useDashboardContext } from '../../context/dashboardContext';

export const getIvrContainment: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'lineChart',
    title: 'IVR Containment',
    description: 'Shows the arrival of contacts that have either be contained, handled or abandoned.',
    header: ChartHeader,
    content: IVRContainmentContent,
    provider: IVRContainmentProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface IVRContainmentWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const IVRContainmentWidgetContext = createContext<IVRContainmentWidgetContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

function IVRContainmentProvider({ children, widgetId }: { children: React.ReactElement; widgetId: string }) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);
  return (
    <IVRContainmentWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </IVRContainmentWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header
      variant='h2'
      description='Shows the arrival of contacts that have either be contained, handled or abandoned.'
    >
      IVR Containment
    </Header>
  );
}

export default function IVRContainmentContent({ widgetId }: { widgetId: string }) {
  const { dashboard } = useDashboardContext();
  const { getWidget } = useDashboardManager();
  const widget = getWidget(widgetId);
  const period = dashboard.data.period;
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  const processData = (data: any[] | DataPayload | undefined) => {
    if (!data || !Array.isArray(data)) return [];
    return data.map((item: { interval_start: string | number | Date; contained: string; abandoned: string; answered: string }) => ({
      date: new Date(item.interval_start),
      contained: parseInt(item.contained, 10),
      abandoned: parseInt(item.abandoned, 10),
      answered: parseInt(item.answered, 10),
    }));
  };

  if (isLoading || error) {
    return (
      <LineChart
        series={[]}
        i18nStrings={{}}
        statusType={isLoading ? 'loading' : 'error'}
        empty={
          <Box
            textAlign='center'
            color='inherit'
          >
            <b>No data available</b>
            <Box
              variant='p'
              color='inherit'
            >
              There is no data available
            </Box>
          </Box>
        }
        noMatch={
          <Box
            textAlign='center'
            color='inherit'
          >
            <b>No matching data</b>
            <Box
              variant='p'
              color='inherit'
            >
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
      />
    );
  }

  const processedData = processData(data?.data);
  const maxContained = Math.max(...processedData.map((item: { contained: any }) => item.contained));
  const maxAbandoned = Math.max(...processedData.map((item: { abandoned: any }) => item.abandoned));
  const maxAnswered = Math.max(...processedData.map((item: { answered: any }) => item.answered));
  const peakVolume = Math.max(maxContained, maxAbandoned, maxAnswered);
  const paddedPeak = peakVolume * 1.1;

  return (
    <LineChart
      {...commonChartProps}
      hideFilter={true}
      fitHeight={true}
      height={25}
      series={[
        {
          title: 'Contained',
          type: 'line',
          data: processedData.map((item: { date: any; contained: any }) => ({
            x: item.date,
            y: item.contained,
          })),
        },
        {
          title: 'Abandoned',
          type: 'line',
          data: processedData.map((item: { date: any; abandoned: any }) => ({
            x: item.date,
            y: item.abandoned,
          })),
        },
        {
          title: 'Answered',
          type: 'line',
          data: processedData.map((item: { date: any; answered: any }) => ({
            x: item.date,
            y: item.answered,
          })),
        },
      ]}
      yDomain={[0, paddedPeak]}
      xDomain={[processedData[0]?.date, processedData[processedData.length - 1]?.date]}
      xScaleType='time'
      xTitle='Time (UTC)'
      yTitle={`Volume of contacts`}
      ariaLabel='IVR Containment'
      ariaDescription={`Line chart showing IVR containment rates ${lineChartInstructions}`}
      i18nStrings={{
        ...commonChartProps.i18nStrings,
        filterLabel: 'Filter',
        filterPlaceholder: 'Filter',
        xTickFormatter: dateTimeFormatter,
      }}
    />
  );
}
