import { Button } from '@aws-amplify/ui-react';
import { Spinner } from '@cloudscape-design/components';
import { useContext } from 'react';
import useIntuitionSWR from '../../../../../../utils/hooks/useIntuitionSWR';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { EmptyState } from '../../components/empty-state';
import WidgetTable from './table';
import { InstanceMetricApiResponse, QueueMetricPreferences } from '../interfaces/queueMetricsInterfaces';
import { InstanceQueueMetricsWidgetContext } from './instanceQueueMetricsProvider';
import { getRealtimeQueueMetricValue, queueArnsToQueueIdParams } from '../../utils/widget.helpers';

export default function InstanceQueueMetrics(props: { widgetId: string }) {
  const { openPreferences } = useContext(InstanceQueueMetricsWidgetContext);
  const { getWidget } = useDashboardManager();

  const widget = getWidget<QueueMetricPreferences>(props.widgetId);

  const queueIdParams = queueArnsToQueueIdParams(widget?.filters?.queues);

  const getParams = queueIdParams ? `?queueIds=${queueIdParams}` : '';

  const endpoint = `/instance-queue-metrics${getParams}`;

  const { data, isLoading, error } = useIntuitionSWR<InstanceMetricApiResponse>(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (error || !data) {
    return (
      <EmptyState
        title='Error retrieving data'
        description='This could be due to no data being received for this queue. Please select a different queue'
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  const { queues } = data;
  // const filteredData = arns[0] !== '*' ? data.filter((item) => arns.includes(item.feature)) : arns.length === 0 ? [] : data;

  queues.sort((a, b) => getRealtimeQueueMetricValue(b, 'OLDEST_CONTACT_AGE') - getRealtimeQueueMetricValue(a, 'OLDEST_CONTACT_AGE'));

  const formattedData = queues.map((queueMetric) => {
    const { minValue, maxValue } = widget.preferences;

    const timeInQueue = getRealtimeQueueMetricValue(queueMetric, 'OLDEST_CONTACT_AGE');
    const status = timeInQueue >= maxValue ? 'error' : timeInQueue >= minValue ? 'warning' : 'success';

    return {
      ...queueMetric,
      status,
    };
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <WidgetTable
      widgetId={props.widgetId}
      isLoading={isLoading}
      data={formattedData}
      error={error}
    />
  );
}
