import * as React from 'react';
import Multiselect from '@cloudscape-design/components/multiselect';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import useIntuitionSWR from '../../../../../../utils/hooks/useIntuitionSWR';
import { useEffect } from 'react';

type Queue = {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: string;
};

interface QueuePickerResponse {
  data: Queue[];
}

type OptionDefinition = {
  label: string;
  value: string;
  description: string;
};

interface QueuePickerProps {
  widgetId: string;
}

const QueuePicker: React.FC<QueuePickerProps> = ({ widgetId }) => {
  const { getWidget, setWidget } = useDashboardManager();
  const [selectedOptions, setSelectedOptions] = React.useState<OptionDefinition[]>([]);

  const { data, error } = useIntuitionSWR<QueuePickerResponse>(`/instance-config/queues`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const isLoading = !data && !error;

  const transformQueueData = (queues: Queue[]): OptionDefinition[] => {
    return [
      {
        label: 'All',
        value: '*',
        description: 'All Queues',
      },
      ...queues.map((queue) => ({
        label: queue.Name,
        value: queue.Arn,
        description: queue.QueueType,
      })),
    ];
  };
  const options = React.useMemo(() => {
    return isLoading || !data ? [] : transformQueueData(data.data);
  }, [isLoading, data]);

  const getInitialSelectedOptions = (): OptionDefinition[] => {
    const currentWidget = getWidget(widgetId);
    if (!currentWidget?.filters?.queues) {
      return [];
    }

    if (currentWidget.filters.queues.includes('*')) {
      return [{ label: 'All', value: '*', description: 'All Queues' }];
    }

    return currentWidget.filters.queues.map((arn: string) => {
      const queue = options.find((opt) => opt.value === arn);
      return queue || { label: arn, value: arn, description: '' };
    });
  };

  useEffect(() => {
    setSelectedOptions(getInitialSelectedOptions);
  }, [options, widgetId]);
  const updateWidgetItem = (selectedArns: string[]) => {
    const currentWidget = getWidget(widgetId);
    setWidget(widgetId, {
      filters: {
        ...currentWidget.filters,
        queues: selectedArns,
      },
    });
  };

  const handleSelectionChange = (selectedOpts: OptionDefinition[]) => {
    if (selectedOpts.some((opt) => opt.value === '*')) {
      setSelectedOptions([{ label: 'All', value: '*', description: 'All Queues' }]);
      updateWidgetItem(['*']);
    } else {
      setSelectedOptions(selectedOpts);
      updateWidgetItem(selectedOpts.map((opt) => opt.value));
    }
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div>
      <Multiselect
        filteringType='auto'
        selectedOptions={selectedOptions}
        onChange={({ detail }) => handleSelectionChange(detail.selectedOptions as OptionDefinition[])}
        options={options}
        placeholder='Select queues'
        tokenLimit={2}
      />
    </div>
  );
};

export default QueuePicker;
