import { Header } from '@cloudscape-design/components';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { WidgetConfig, WidgetHeaderProps } from '../interfaces';
import { SingleMetricContent } from './singleMetricContent';
import { SingleMetricProvider } from './provider';
import { SingleMetricPreferences } from './singleMetricTypes';
import styles from './singleMetric.module.scss';

export const getSingleMetric: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'number',
    title: 'Single Metric',
    description: 'Displays a single metric',
    header: WidgetHeader,
    content: SingleMetricContent,
    provider: SingleMetricProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
    className: styles.singleMetricWidget,
  },
  filters: {
    queues: [],
  },
};

function WidgetHeader({ widgetId }: WidgetHeaderProps) {
  const { getWidget } = useDashboardManager();

  const widget = getWidget<SingleMetricPreferences>(widgetId);
  const { title, description } = widget.data;
  return (
    <Header
      variant='h2'
      description={title}
    >
      {description}
    </Header>
  );
}
