import React, { useState } from 'react';
import Box from '@cloudscape-design/components/box';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import Modal from '@cloudscape-design/components/modal';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import FormField from '@cloudscape-design/components/form-field';
import { useInitialise } from '../../../../../../utils/hooks/useInitialise';
import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import * as awsui from '@cloudscape-design/design-tokens';
import { QueueMetricPreferences } from '../interfaces/queueMetricsInterfaces';
import { Input } from '@cloudscape-design/components';
import styles from './preferences.module.scss';
// import QueuePicker from '../../components/queue-picker';
import QueuePicker from '../../components/queue-picker';

export interface WidgetPreferencesProps {
  onDismiss: () => void;
  widgetId: string;
}

export function WidgetPreferences({ onDismiss, widgetId }: WidgetPreferencesProps) {
  const { getWidget, setWidget } = useDashboardManager();
  const [selectedRange, setSelectedRange] = useState<ChangeResult>({
    min: 0,
    max: 100,
    maxValue: 100,
    minValue: 0,
  });

  const widget = getWidget<QueueMetricPreferences>(widgetId);

  useInitialise(() => {
    widget.preferences.max && setSelectedRange(widget.preferences);
  });

  const handleLimitChange = (event: ChangeResult) => {
    setSelectedRange(event);
    const preferences = {
      ...widget.preferences,
      ...event,
    };
    setWidget(widgetId, { preferences });
  };
  const handleRangeChange = (max: string) => {
    setSelectedRange((previousItem) => ({ ...previousItem, max: +max }));
    setWidget(widgetId, { preferences: { ...widget.preferences, max: +max } });
  };

  const sliderProps = {
    ...selectedRange,
    // label: 'Longest Wait time thresholds',
    step: 1,
    onChange: handleLimitChange,
    ruler: false,
    style: { border: 'none', boxShadow: 'none', padding: '15px 10px' },
    barLeftColor: awsui.colorChartsStatusPositive,
    barInnerColor: awsui.colorChartsStatusMedium,
    barRightColor: awsui.colorChartsStatusHigh,
  };

  const handleTitleChange = (title: string) => {
    setWidget(widgetId, { title });
  };

  return (
    <Modal
      visible={true}
      header='Preferences'
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >
        <FormField
          description='Select a queue from the dropdown below to view its realtime data'
          label='Queue'
        >
          <QueuePicker widgetId={widgetId} />
        </FormField>

        <FormField description='Set the name of the widget'>
          <Input
            value={widget.data.title}
            onChange={({ detail }) => handleTitleChange(detail.value)}
          />
        </FormField>

        <FormField
          description='Longest Wait Time thresholds'
          className={styles.sliderFormField}
        >
          <MultiRangeSlider {...sliderProps} />
        </FormField>
        <FormField description='Set upper limit of "Longest Wait Time"'>
          <Input
            onChange={({ detail }) => handleRangeChange(detail.value)}
            value={`${selectedRange.max}`}
            type='number'
          />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
}
