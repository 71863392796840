import React, { createContext, useMemo, useState } from 'react';
import { Header } from '@cloudscape-design/components';
import { WidgetConfig, WidgetProviderProps } from '../interfaces';
import { allContent, Content, WidgetPreferences } from '../preferences';
import AgentSummaryStatsContent from './agentSummaryStatsContent';

export const getAgentSummaryStats: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'table',
    title: 'Agent performance',
    description: 'Table with key metrics, grouped by agent',
    header: ChartHeader,
    content: AgentSummaryStatsContent,
    provider: AgentSummaryStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface AgentSummaryStatsWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const AgentSummaryStatsWidgetContext = createContext<AgentSummaryStatsWidgetContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

function AgentSummaryStatsProvider({ children, widgetId }: WidgetProviderProps) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);

  const openPreferences = () => setPreferencesVisible(true);

  const value = useMemo(() => ({
    visibleContent,
    openPreferences,
  }), []);
  return (
    <AgentSummaryStatsWidgetContext.Provider { ...{value}} >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </AgentSummaryStatsWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header variant="h2" description="Table with key metrics, grouped by agent">
      Agent Performance
    </Header>
  );
}
