import { Badge, Link, Spinner, Table, TableProps } from '@cloudscape-design/components';
import _ from 'lodash';
import { useCallback } from 'react';
import useInstanceSWR from '../../../../../../utils/hooks/useInstanceSWR';
import { EmptyState } from '../../components/empty-state';

interface ClosureItem {
  feature: string;
  description: string;
  timezone: string;
  status: string;
}

export function Closures() {
  const editClosureLink = useCallback((item: ClosureItem) => {
    return (
      <Link
        target='_blank'
        rel='noopener noreferrer'
        href={`/closures/edit/${item.feature}`}
      >
        {item.feature}
      </Link>
    );
  }, []);

  const closureStatus = useCallback((item: ClosureItem) => {
    if (item?.status === 'unplannedClosure') {
      return <Badge color='red'>Unplanned Closure</Badge>;
    } else if (item?.status === 'closed') {
      return <Badge color='grey'>Closed</Badge>;
    } else if (item?.status === 'open') {
      return <Badge color='green'>Open</Badge>;
    } else if (item?.status === 'plannedClosure') {
      return <Badge color='blue'>Planned Closure</Badge>;
    } else {
      return <>-</>;
    }
  }, []);

  const { data, isLoading, error } = useInstanceSWR('/closures', {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (error || !data) {
    return (
      <EmptyState
        title='Error retrieving data'
        description='Please check your connection and try again'
        verticalCenter={true}
      />
    );
  }

  const tableDefinition: TableProps<ClosureItem>['columnDefinitions'] = [
    {
      id: 'feature',
      header: 'Group',
      cell: editClosureLink,
      width: 75,
      isRowHeader: true,
    },
    {
      id: 'status',
      header: 'Status',
      cell: closureStatus,
    },
  ];

  const filteredData = _.filter(data.data, { status: 'unplannedClosure' });

  return (
    <Table
      variant='borderless'
      items={filteredData}
      columnDefinitions={tableDefinition}
      loading={isLoading}
    />
  );
}
