import { Header } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import { Closures } from './closuresContent';

export const getClosures: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'list',
    title: 'Unplanned Closures',
    description: `Shows only queues that are currently in the status of "unplanned closure"`,
    header: ChartHeader,
    content: Closures,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

function ChartHeader() {
  return <Header variant='h2'>Unplanned Closures</Header>;
}
