import React from 'react';
import {Container, Alert, SpaceBetween, ContentLayout, Header} from '@cloudscape-design/components';
import {useParams} from "react-router-dom";

export function DashboardError() {
    const {featureId} = useParams();

    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header>
                        {featureId}
                    </Header>
                </SpaceBetween>
            }
        >
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                        Oh no!
                    </Header>
                }
            >
                <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="Your dashboard information cannot be retreived"
                >
                    Check your connectivity and reload the page
                </Alert>
            </Container>
        </ContentLayout>
    );
}
