import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import CopyFormLayout, { ConfigStructure } from '../../../components/copyForm/copyFormLayout';
import { Alert, ContentLayout, Header, Spinner } from '@cloudscape-design/components';
import { DashboardDataRepresentation } from '../view/dashboard/widgets/interfaces';

export default function VisualizationsCopy() {
  const hasMigration = false;
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();

  const { data, isLoading, error } = useInstanceSWR<DashboardDataRepresentation>(`/intuition/dashboards/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const validation = useCallback((feature: string, isMigrating: boolean) => {
    const generalPattern = /^[\w\-\s]+$/;
    if (!isMigrating && feature === data?.data?.title) {
      return 'Try a different dashboard group name';
    } else if (!generalPattern.test(feature)) {
      return 'Only alphanumeric, - _ and + supported';
    }
  }, [data?.data.title]);

  const handleCancel = useCallback(() => {
    navigate('/visualizations');
  }, [navigate]);

  const handleFormSubmit = useCallback(async (dashboardItem: ConfigStructure<DashboardDataRepresentation['data']>) => {
    try {

      await createDashboard(dashboardItem);

      navigate('/visualizations');
    } catch (error) {
      console.error('Error handling form submit:', error);
    }
  }, [navigate])

  async function createDashboard(dashboardItem: ConfigStructure<DashboardDataRepresentation['data']>) {
    const { feature: title, ...payload } = dashboardItem;
    return handleApiWithFlash(`/intuition/dashboards`, 'POST', {
      errorMessage: `Error copying Dashboard ${title}`,
      data: { ...payload, title },
      successMessage: ``,
    });
  }

  if (error) {
    return (
      <ContentLayout header={<Header variant='h1'>Error</Header>}>
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Unable to load component'
        >
          {error?.status}
        </Alert>
      </ContentLayout>
    );
  }

  if (isLoading || !data?.data) {
    return (
      <ContentLayout header={<Header variant='h1'>Loading</Header>}>
        <Spinner />
      </ContentLayout>
    );
  }

  const item = {
    ...data.data,
    feature: data.data.title ?? data.data.feature,
  };

  return (
    <CopyFormLayout
      item={item}
      isLoading={isLoading}
      hasMigration={hasMigration}
      validation={validation}
      handleFormSubmit={handleFormSubmit}
      handleCancel={handleCancel}
    />
  );
}
