import React, { createContext, useState } from 'react';
import { WidgetComponentProviderProps } from '../../../../types';
import { WidgetPreferences } from '../preferences';

export interface QueueSummaryStatsWidgetContextType {
  openPreferences: () => void;
}

export const QueueSummaryStatsWidgetContext = createContext<QueueSummaryStatsWidgetContextType>({
  openPreferences: () => null,
});

export function QueueSummaryStatsProvider({ children, widgetId }: WidgetComponentProviderProps) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  return (
    <QueueSummaryStatsWidgetContext.Provider
      value={{
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </QueueSummaryStatsWidgetContext.Provider>
  );
}
