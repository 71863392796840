import { ContentLayout } from '@cloudscape-design/components';
import { DashboardHeader } from './components/header';

import { useDashboardContext } from './context/dashboardContext';
import { Grid } from './components/grid';

export const Dashboard = () => {
  const { isLoading } = useDashboardContext();

  return (
    <ContentLayout header={<DashboardHeader />}>
      {!isLoading && <Grid />}
    </ContentLayout>
  );
};
