/*
  This context provider will all the tenant config to be access from within the app.

  To access the data:

  const tenantData = useTenant();
  const supportTier = tenantData?.supportTier;
  const memberType = tenantData?.memberType;

  This is used to set views and allows access to areas of the application.

 */

import { Auth } from 'aws-amplify';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import fetcher from '../fetcher';
import instanceFetcher from '../fetcherInstance';
import useInstanceSWR from './useInstanceSWR';
import { InstanceSelected, InstanceType, STORED_INSTANCE_SELECTOR } from '../../types/instance';
import { getSelectedInstance } from '../../components/localStorage';
import { InstancePermissions } from '../../types/rolePermissions';
import { useInitialise } from './useInitialise';
import useSWR from 'swr';
import { ApiResponse } from '../../types/api';
import { Layout } from 'react-grid-layout';

interface TenantContextProps {
  children: ReactNode;
}

interface UserProfile {
  given_name: string;
  family_name: string;
  email: string;
  'custom:securityProfile': string;
  'custom:tenantId': string;
  'custom:roles'?: string;
  sub: string;
}

export interface AppSessionContextValue {
  supportTier: string | null;
  memberType: string | null;
  userProfile: UserProfile | null;
  fullScreen: boolean;
  setFullScreen: (state: boolean) => void;
  instances: Array<InstanceType>;
  instancesLoading: boolean;
  instancesError: boolean;
  instanceSelected: InstanceSelected | null;
  setInstanceSelected: (instance: InstanceSelected) => Promise<void>;
  instancePermissions?: InstancePermissions;
  activeWidget: { templateType: string, size: Layout} | undefined,
  setActiveWidget: React.Dispatch<React.SetStateAction< { templateType: string, size: Layout} | undefined>>,
}

const defaultContext = {
  supportTier: null,
  memberType: null,
  userProfile: null,
  fullScreen: false,
  setFullScreen: (_state: boolean) => null,
  instances: [],
  instancesLoading: false,
  instancesError: false,
  instanceSelected: null,
  setInstanceSelected: (_instance: InstanceSelected) => Promise<void>,
  instancePermissions: null,
  activeWidget: undefined,
  setActiveWidget: () => null
}

const SessionContext = createContext<AppSessionContextValue | null>(defaultContext as unknown as AppSessionContextValue);

export const useAppSession = () => {
  const context = useContext(SessionContext);
  if(!context) {
    throw new Error('useAppSession must be used within a AppSessionProvider');
  }
  return context;
};

export const AppSessionProvider = ({ children }: TenantContextProps) => {
  const [supportTier, setSupportTier] = useState<string | null>(null);
  const [memberType, setMemberType] = useState<string | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [instanceSelected, setInstanceSelected] = useState<InstanceSelected | null>(null);
  const [instancePermissions, setInstancePermissions] = useState<InstancePermissions>({});
  const [instances, setInstances] = useState<Array<InstanceType>>([]);
  const [instancesLoading, setInstancesLoading] = useState<boolean>(false);
  const [activeWidget, setActiveWidget] = useState< { templateType: string, size: Layout}>();


  const {
    data: instanceData,
    isLoading: instancesIsLoading,
    isValidating: instancesIsValidating,
    error: instancesError,
  } = useSWR<ApiResponse<Array<InstanceType>>>('/instances', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useInitialise(() => {
    const getUserProfile = async () => {
      const profile = await Auth.currentAuthenticatedUser();

      const attributes = profile.attributes ?? profile.signInUserSession.idToken.payload;
      const tmpUserProfile = {
        given_name: attributes.given_name,
        family_name: attributes.family_name,
        email: attributes.email,
        'custom:securityProfile': attributes['custom:securityProfile'],
        'custom:tenantId': attributes['custom:tenantId'],
        'custom:roles': attributes['custom:roles'],
        sub: attributes.sub,
      };
      setUserProfile(tmpUserProfile);
    };

    getUserProfile();
  });

  useInitialise(() => {
    const storedInstance = getSelectedInstance();
    if (storedInstance) {
      setInstanceSelectedContext(storedInstance, false);
    }
  });

  const updateCurrentInstance = () => {
    if (instanceSelected) {
      const dbInstanceSelected = instanceData?.data?.find((instance) => instance.instanceArn === instanceSelected.instanceArn);
      const instanceHasChanged = dbInstanceSelected && dbInstanceSelected?.instanceName !== instanceSelected.instanceAlias;
      instanceHasChanged &&
        setInstanceSelectedContext({
          instanceArn: dbInstanceSelected?.instanceArn,
          instanceAlias: dbInstanceSelected?.instanceName,
          instanceRegion: dbInstanceSelected?.instanceRegion,
          instanceIntuitionUrl: dbInstanceSelected?.intuitionUrl,
          instanceTimezone: dbInstanceSelected?.timezone,
        });
    }
  };

  const setInitialSelectedInstance = () => {
    const storedInstance = getSelectedInstance();

    if (!storedInstance && instances && instances.length > 0) {
      const [firstInstance] = instances;
      const selectedData: InstanceSelected = {
        instanceArn: firstInstance.instanceArn,
        instanceAlias: firstInstance.instanceName,
        instanceRegion: firstInstance.instanceRegion,
        instanceIntuitionUrl: firstInstance.intuitionUrl,
        instanceTimezone: firstInstance.timezone,
      };

      setInstanceSelectedContext(selectedData);
    }
  };

  useEffect(() => {
    setInstances(instanceData?.data || []);
    setInstancesLoading(instancesIsLoading || instancesIsValidating);
  }, [instanceData, instancesIsLoading, instancesIsValidating]);

  useEffect(() => {
    updateCurrentInstance();
    setInitialSelectedInstance();
  }, [instances, instancesLoading]);

  async function setInstanceSelectedContext(instance: InstanceSelected, updateLocalStorage = true) {
    const response = await instanceFetcher('/permissions', 'GET', undefined, instance.instanceArn);

    setInstanceSelected(instance);
    setInstancePermissions(response.data);
    updateLocalStorage && localStorage.setItem(STORED_INSTANCE_SELECTOR, JSON.stringify(instance));
  }

  const { data, error } = useInstanceSWR('/tenantProfile', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data && data.data) {
      const { supportTier, memberType } = data.data;
      setSupportTier(supportTier);
      setMemberType(memberType);
    }
    if (error) {
      console.error('Error fetching tenant data:', error);
    }
  }, [data, error]);

  return (
    <SessionContext.Provider
      value={{
        activeWidget, 
        setActiveWidget,
        supportTier,
        memberType,
        userProfile,
        fullScreen,
        setFullScreen,
        instances,
        instancesLoading,
        instancesError,
        instanceSelected,
        setInstanceSelected: setInstanceSelectedContext,
        instancePermissions,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
