import { Header, SpaceBetween, Box, Popover, Button, ButtonProps, SplitPanel } from '@cloudscape-design/components';
import CxDateRangePicker from '../../../../../../components/cxDataRangePicker';
import { ProtectedUtilityUpdate } from '../../../../../../components/protectedUtility';
import { ResourceTypes } from '../../../../../../types/rolePermissions';
import { useAppSession } from '../../../../../../utils/hooks/sessionContext';
import { useDashboardContext } from '../../context/dashboardContext';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { useSplitPanelControl } from '../../../../../../utils/hooks/splitPanelContext';
import { useNavigate } from 'react-router-dom';
import { getPaletteWidgets } from '../../widgets';
import Palette from '../pallette';
import { useEffect } from 'react';
import { useBlockerLogic } from '../../../../../../utils/hooks/useBlocker';
import { ConfirmCancelModal } from '../../../../../../components/confirmCancelModal';

export const DashboardHeader = () => {
  const appSession = useAppSession();
  const navigate = useNavigate();
  const { dashboard, isLoading, changesDetected: dashboardChangesDetected } = useDashboardContext();
  const { saveDashboard, resetDashboard, handleDateChange } = useDashboardManager();
  const { splitPanelOpen, setSplitPanelOpen, setSplitPanelContent, setSplitPanelPreferences } = useSplitPanelControl();

  const { blocker, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected: dashboardChangesDetected,
    path: 'visualizations',
    formSubmitted: isLoading,
  });

  useEffect(() => {
    if (dashboard?.data?.items && splitPanelOpen) {
      handleOpenWidgetDrawer();
    }
  }, [dashboard]);

  const goToEditDashboard = () => navigate(`/visualizations/edit/${dashboard.feature}`);

  function handleOpenWidgetDrawer() {
    const dashboardIds = dashboard.data.items.map(({ id }) => id);

    const widgets = getPaletteWidgets(dashboardIds);
    setSplitPanelContent(
      <SplitPanel
        header='Add widgets'
        closeBehavior='hide'
        hidePreferencesButton={true}
      >
        <Palette items={widgets} />
      </SplitPanel>,
    );
    setSplitPanelOpen(true);
    setSplitPanelPreferences({ position: 'side' });
  }

  const fullScreenToggleProps: ButtonProps = {
    iconName: appSession?.fullScreen ? 'shrink' : 'expand',
    onClick: () => appSession?.setFullScreen(!appSession?.fullScreen),
    variant: 'icon',
  };

  return (
    <>
    <Header
      actions={
        <SpaceBetween
          size='xs'
          direction='horizontal'
        >
          {dashboard?.data?.period?.type === 'relative' && (
            <Box
              color='text-status-info'
              display='inline'
            >
              <Popover
                header='Live data'
                size='medium'
                triggerType='text'
                content={<>When using relative time ranges, data is automatically updated every 30 seconds.</>}
                renderWithPortal={true}
              >
                <Box
                  color='text-status-info'
                  fontSize='body-s'
                  fontWeight='bold'
                >
                  Live data
                </Box>
              </Popover>
            </Box>
          )}
          {dashboardChangesDetected && <Button onClick={() => resetDashboard()}>Reset</Button>}
          {dashboardChangesDetected && (
            <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
              <Button
                variant={'primary'}
                onClick={saveDashboard}
                loading={isLoading}
              >
                Save Changes
              </Button>
            </ProtectedUtilityUpdate>
          )}
          <CxDateRangePicker
            setDateRangeValue={handleDateChange}
            defaults={dashboard?.data?.period}
          />
          <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
            {!appSession?.fullScreen && (
              <Button
                iconName='add-plus'
                onClick={() => handleOpenWidgetDrawer()}
              >
                Add widget
              </Button>
            )}
          </ProtectedUtilityUpdate>
          <div>
            <Button {...fullScreenToggleProps} />
          </div>
        </SpaceBetween>
      }
    >
      {dashboard?.title ?? dashboard.feature} &nbsp;
      <ProtectedUtilityUpdate resourceType={ResourceTypes.Dashboards}>
        {!appSession?.fullScreen && (
          <Button
            iconName='edit'
            onClick={() => goToEditDashboard()}
          />
        )}
      </ProtectedUtilityUpdate>
    </Header>
    {blocker.state === 'blocked' && (
        <ConfirmCancelModal
          {...{
            onCancel: handleCloseCancelModal,
            onConfirm: handleConfirmCancel,
            cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
          }}
        />
      )}
      </>
  );
};
