import React from 'react';
import { Box, Button, ColumnLayout, FormField, Modal, SpaceBetween, Input, InputProps } from '@cloudscape-design/components';
import { NonCancelableCustomEvent } from '@cloudscape-design/components/internal/events';
import { useDashboardManager } from '../../hooks/useDashboardManager';

interface WidgetPreferencesProps {
  preferences: ReadonlyArray<any>;
  onDismiss: () => void;
  onConfirm: (visibleContent: ReadonlyArray<any>) => void;
  widgetId: string;
}

export function WidgetPreferences(props: WidgetPreferencesProps) {
  const { onDismiss, widgetId } = props;
  const { getWidget, setWidget } = useDashboardManager();
  const widget = getWidget<{ dashboardId?: string; header?: string }>(widgetId);

  const initialPreferences = widget?.preferences || {};
  const dashboardId = initialPreferences?.dashboardId ?? '';
  const header = initialPreferences?.header ?? '';

  function setDashboardContext(preferenceName: string, value: string, widgetId: string) {
    const preferences = {
      ...(widget.preferences || {}),
      [preferenceName]: value,
    };
    setWidget(widgetId, { preferences });
  }

  function onDashboardIdInputChange(event: NonCancelableCustomEvent<InputProps.ChangeDetail>) {
    const value = event.detail.value;
    setDashboardContext('dashboardId', value, widgetId);
  }

  function onHeadingInputChange(event: NonCancelableCustomEvent<InputProps.ChangeDetail>) {
    const value = event.detail.value;
    setDashboardContext('header', value, widgetId);
  }

  return (
    <Modal
      visible={true}
      header='Preferences'
      onDismiss={onDismiss}
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >
        <FormField
          description='Enter the Quicksight Dashboard ID'
          label='Dashboard ID'
        >
          <Input
            name='dashboardId'
            onChange={onDashboardIdInputChange}
            value={dashboardId}
          />
        </FormField>
        <FormField
          description="Enter the Header for the tile.  (Defaults to 'Quicksight Dashboard')"
          label='Widget Header'
        >
          <Input
            name='header'
            onChange={onHeadingInputChange}
            value={header}
          />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
}
