import React, { useId } from 'react';
import Box from '@cloudscape-design/components/box';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import Modal from '@cloudscape-design/components/modal';
import styles from './preferences.module.scss';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Toggle, { ToggleProps } from '@cloudscape-design/components/toggle';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import QueuePicker from '../../components/queue-picker';
import FormField from '@cloudscape-design/components/form-field';

export const allContent = [
  'AGENTS_AFTER_CONTACT_WORK',
  'AGENTS_AVAILABLE',
  'AGENTS_ERROR',
  'AGENTS_NON_PRODUCTIVE',
  'AGENTS_ON_CALL',
  'AGENTS_ON_CONTACT',
  'AGENTS_ONLINE',
  'AGENTS_STAFFED',
  'CONTACTS_IN_QUEUE',
  'CONTACTS_SCHEDULED',
  'OLDEST_CONTACT_AGE',
  'SLOTS_ACTIVE',
  'SLOTS_AVAILABLE',
] as const;

export type Content = (typeof allContent)[number];

interface WidgetPreferencesProps {
  preferences: ReadonlyArray<Content>;
  onDismiss: () => void;
  onConfirm: (visibleContent: ReadonlyArray<Content>) => void;
  widgetId: string;
}

const ToggleForwarder = ({ controlId, checked, onChange }: Pick<ToggleProps, 'controlId' | 'checked' | 'onChange'>) => {
  return (
    <Toggle
      controlId={controlId}
      checked={checked}
      onChange={onChange}
    />
  );
};

export function WidgetPreferences({ onDismiss, widgetId }: Readonly<WidgetPreferencesProps>) {
  const { getWidget, setWidget } = useDashboardManager();
  const widget = getWidget(widgetId);

  const initialPreferences = widget?.preferences || [];

  function toggle(content: Content, checked: boolean) {
    let newPreferences = [...(widget.preferences || [])];
    if (checked) {
      newPreferences.push(content);
    } else {
      newPreferences = newPreferences.filter((item) => item !== content);
    }
    setWidget(widgetId, { preferences: newPreferences });
  }

  return (
    <Modal
      visible={true}
      header='Preferences'
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >
        <FormField
          description='Select a queue from the dropdown below to view its realtime data'
          label='Queue'
        >
          <QueuePicker widgetId={widgetId} />
        </FormField>
        {allContent.map((content) => {
          const label = content
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          const onChange: ToggleProps['onChange'] = (event) => toggle(content, event.detail.checked);

          return (
            <PreferencesControl
              key={content}
              label={label}
              toggle={(id) => (
                <ToggleForwarder
                  controlId={id}
                  checked={initialPreferences.includes(content)}
                  onChange={onChange}
                />
              )}
            />
          );
        })}
      </ColumnLayout>
    </Modal>
  );
}

interface PreferencesControlProps {
  label: string;
  isGroup?: boolean;
  toggle?: (id: string) => React.ReactNode;
}

function PreferencesControl({ label, toggle, isGroup }: Readonly<PreferencesControlProps>) {
  const id = useId();
  return (
    <div className={styles.displayPreference}>
      <label
        htmlFor={id}
        className={`${styles.displayPreferenceLabel} ${isGroup ? styles.displayPreferenceGroup : ''}`}
      >
        {label}
      </label>
      {toggle?.(id)}
    </div>
  );
}
